import React from 'react'
import styled from '@emotion/styled'
import AppleSVG from '../images/vectors/apple_black.inline.svg'
import WindowsSVG from '../images/vectors/windows.inline.svg'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    padding: 30px 15px;
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px;
    }
  }
`

const ContainerLinks = styled.div`
  display: flex;
  flex: auto;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-flow: row nowrap;
  padding: 30px 15px;

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    justify-content: space-between;
  }
`

const LinkContainer = styled.a`
  height: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 1 300px;
  border: 2px solid #000;
  margin: 0 0 30px;

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    flex: 0 1 49%;
    height: 150px;
  }
`

const IconContainer = styled.div`
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    height: 80px;
  }
`

const StyledApple = styled(AppleSVG)`
  width: 90px;
  height: auto;
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    width: 60px;
  }
`

const StyledWindows = styled(WindowsSVG)`
  width: 90px;
  height: auto;
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    width: 60px;
  }
`

const ContentFernwartung = props => {
  return (
    <Container>
      {props.children}
      <ContainerLinks>
        <LinkContainer
          href="https://download.teamviewer.com/download/TeamViewerQS.dmg"
          rel="noreferrer"
        >
          <IconContainer>
            <StyledApple />
          </IconContainer>
          Für Apple
        </LinkContainer>
        <LinkContainer
          href="https://download.teamviewer.com/download/TeamViewerQS.exe"
          rel="noreferrer"
        >
          <IconContainer>
            <StyledWindows />
          </IconContainer>
          Für Windows
        </LinkContainer>
      </ContainerLinks>
    </Container>
  )
}

export default ContentFernwartung
