import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContentFernwartung from '../components/ContentFernwartung'
import SEO from '../components/SEO'

const Fernwartung = () => {
  return (
    <Layout>
      <SEO title="Fernwartung" description="Laden Sie sich unser präferiertes Tool zur Fernwartung herunter." />
      <Container>
        <ContainerHero>
          <ContentFernwartung>
            <PageTitle>Fernwartung</PageTitle>
          </ContentFernwartung>
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Fernwartung